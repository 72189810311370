// extracted by mini-css-extract-plugin
export var ArtistDescription = "ToddJames-module--ArtistDescription--tmw6v";
export var ArtistInfos = "ToddJames-module--ArtistInfos--UAKpE";
export var ButtonWrapper = "ToddJames-module--ButtonWrapper --c-Nak";
export var CardWrapper = "ToddJames-module--CardWrapper--b8UwK";
export var CarrouselWrapper2 = "ToddJames-module--CarrouselWrapper2--JYqO+";
export var Citations = "ToddJames-module--Citations--u4etO";
export var DescriptionWrapper = "ToddJames-module--DescriptionWrapper--pFPcn";
export var ImageWrapper = "ToddJames-module--ImageWrapper--yDEeB";
export var LinkWrapper = "ToddJames-module--LinkWrapper--QcAX1";
export var MobileProtrait = "ToddJames-module--MobileProtrait--x4QJV";
export var More = "ToddJames-module--More--fi1R1";
export var MoreButton = "ToddJames-module--MoreButton--bYwQp";
export var NameWrapper = "ToddJames-module--NameWrapper--y82ly";
export var PdpWrapper = "ToddJames-module--PdpWrapper--YZZQ9";
export var PhotosWrapper = "ToddJames-module--PhotosWrapper--maaNT";
export var ProfilWrapper = "ToddJames-module--ProfilWrapper--g-tKU";
export var TitleWrapper = "ToddJames-module--TitleWrapper--yPaZe";
export var Wrapper = "ToddJames-module--Wrapper--K+Fe3";